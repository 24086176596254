import React, { memo } from "react";
import { useNavigate } from "react-router-dom";
import { createSlug } from "../Widgets/Slug/Slug";

const OurTeam = ({
  src,
  name,
  lastName,
  data1,
  data2,
  data3,
  data4,
  uniqueId,
  lawyerEmail,
}) => {
  const history = useNavigate();
  const currentURL = window.location.pathname;
  return (
    <>
      {currentURL !== "/meet-the-lawyer" && (
        <div className="col-sm-6 col-md-6 col-lg-3 mob_mt ">
          <div className="circle-icon gallery-item overflow-hidden" style={{}}>
            {/* <img src={src === "" ? "/assets/img/on_profile.png" : src} alt="" /> */}
            <img src={src === "" ? "/assets/img/on_profile.jpg" : src} alt="" />
          </div>
          <div className="service-box lift position-relative rounded-1 bg-shadow text-center for-cursor">
            <h4 className="service-box-title mb-10">{name}</h4>
            <p className="service-box-text font-size-15 mb-10 font-geograph">
              {data1}
            </p>
            <p className="service-box-text font-size-15 mb-10 font-geograph-italic">
              {data2}
            </p>
            <p>{data3?.replace(/(<([^>]+)>)/gi, " ")}</p>
            <div className="service-box-arrow d-flex align-items-center justify-content-center">
              <div className="me-10">
                <span
                  onClick={(e) => {
                    e.preventDefault();
                    window.scrollTo(0, 0);
                    const fullName = name + " " + lastName
                    const slug = createSlug(fullName);
                    history(`/meet-the-lawyer/${slug}`);
                  }}
                  className=" for-cursor mt-30"
                >
                  {data4}
                </span>
              </div>
              <svg
                width={7}
                height={13}
                viewBox="0 0 7 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1.13782L6 6.63782L1 12.1378"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            {/* 641045375a0feba1aae8f2a6-sarah (uat) */}
            {/* 641045765a0feba1aae8f2b3-alpha (uat) */}
            {/* 64101f7d6859129f0357e2d1-sarah (dev) */}
            {/* 64101f0d6859129f0357e2c0-alpha (dev) */}
            {currentURL === "/select-lawyer" && (
              <>
                {uniqueId != "641045375a0feba1aae8f2a6" &&
                uniqueId != "641045765a0feba1aae8f2b3" &&
                uniqueId != "64101f7d6859129f0357e2d1" &&
                uniqueId != "64101f0d6859129f0357e2c0" ? (
                  <a
                    className="btn btn-accent-1 mt-6 select_btn"
                    onClick={(e) => {
                      localStorage.setItem("lawyerEmail", lawyerEmail);
                      history(`/consultation-date/lawyer/${uniqueId}`);
                    }}
                  >
                    Select
                  </a>
                ) : null}
              </>
            )}
          </div>
        </div>
      )}

      {currentURL === "/meet-the-lawyer" && (
        <div className="  col-12 col-sm-6 col-md-6 col-lg-3 mb-30 mob_mt ">
          <div className="circle-icon gallery-item overflow-hidden" style={{}}>
            {/* <img src={src === "" ? "/assets/img/on_profile.png" : src} alt="" /> */}
            <img src={src === "" ? "/assets/img/on_profile.jpg" : src} alt="" />
          </div>
          <div className="service-box lift position-relative rounded-4 bg-shadow text-center for-cursor">
            <h4 className="service-box-title mb-10">{name  + " " + lastName}</h4>
            <p className="service-box-text font-size-15 mb-10 font-geograph font-700">
              {data1}
            </p>
            <p className="service-box-text font-size-15 mb-10 font-geograph-italic">
              {data2}
            </p>
            <p>{data3?.replace(/(<([^>]+)>)/gi, " ")}</p>
            <div className="service-box-arrow d-flex align-items-center justify-content-center">
              <div className="me-10">
                <span
                  onClick={(e) => {
                    window.scrollTo(0, 0);
                    e.preventDefault();
                    const fullName = name + " " + lastName
                    const slug = createSlug(fullName);
                    history(`/meet-the-lawyer/${slug}`);
                  }}
                  className=" stretched-link mt-30"
                >
                  {data4}
                </span>
              </div>
              <svg
                width={7}
                height={13}
                viewBox="0 0 7 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1.13782L6 6.63782L1 12.1378"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default memo(OurTeam);
